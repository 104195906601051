/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */

import React, { Component } from 'react'
import rehypeReact from 'rehype-react'
import DefaultLayout from './../layouts/Default'
import SEO from './../components/Seo'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Cta from './../components/Cta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from './../data/SiteConfig'
import ExternalLink from './../components/ExternalLink'
import MiniSubscriptionForm from './../components/SubscribeForm/Mini'
import SocialShare from './../components/SocialShare'
import Comments from './../components/Comments'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'external-link': ExternalLink,
    'subscription-form': MiniSubscriptionForm
  }
}).Compiler

/**
 * Project detail page template.
 */
class ProjectTemplate extends Component {
  render () {
    const project = this.props.data.projectData
    const projectMeta = this.props.data.projectMetaData
    return (
      <DefaultLayout className={`page-project markdown-content-page`}>
        <SEO
          pageType={`article`}
          pageTitle={project.frontmatter.title}
          fullPageTitle={project.frontmatter.title}
          pageDescription={project.frontmatter.description}
          pageImage={project.frontmatter.static_image}
          pagePath={project.frontmatter.path}
          articlePublishDate={projectMeta.frontmatter.updated}
          articleTags={project.frontmatter.tags}
        />
        <div className="container">
          <div className="row pt-lg-12 pt-10">
            <article className="col-lg-9 col-12">
              <h1>{project.frontmatter.title}</h1>
              <p className="tags-container">
                {project.frontmatter.tags.map(tag =>
                  <span className="badge badge-secondary mr-2 tag" key={tag}>
                    {tag}
                  </span>
                )}
              </p>
              <div className="row">
                <div className="col-12">
                  <p className="article-details">
                    <small>Last updated: {project.frontmatter.updated} By: <Link
                      to={ config.defaultAuthor.relative_url }
                      title={`Developed by ${ config.defaultAuthor.name }`}
                      className={`hover-none hover-darken`}
                    >{ config.defaultAuthor.name }</Link>
                    </small>
                  </p>
                  {(project.frontmatter.ci_badge_url ||
                    (project.frontmatter.fury_badge_url))
                    ? (
                    <p>
                      {project.frontmatter.ci_badge_url ? (
                        <img
                          src={project.frontmatter.ci_badge_url}
                          alt={project.frontmatter.ci_badge_alt}
                        />
                      ) : (null)}
                      {project.frontmatter.fury_badge_url ? (
                        <a className={`ml-3`} target={`_blank`} rel={`noopener noreferrer`} href={project.frontmatter.fury_badge_url} title={project.frontmatter.fury_badge_alt}><img
                          src={project.frontmatter.fury_badge_image_url} alt={project.frontmatter.fury_badge_alt}/></a>
                      ) : (null)}
                    </p>
                  ) : (null)}
                </div>
              </div>
              <Img
                fluid={project.frontmatter.cover_image.childImageSharp.fluid}
                className={`rounded mt-5`}
              />
              <div className="row justify-content-center">
                <SocialShare
                  path={project.frontmatter.path}
                  description={project.frontmatter.description}
                  title={project.frontmatter.title}
                  tags={project.frontmatter.tags}
                  className={`pt-3 pb-lg-0 pb-3`}
                />
              </div>
              <div className="content pt-lg-8">
                {renderAst(project.htmlAst)}
              </div>
              <div className="row justify-content-center text-center pt-5">
                <div className="col-12">
                  <p><strong>Was this project useful to you?</strong></p>
                  <p>Don't be selfish, share it with your friends 😉</p>
                </div>
                <SocialShare
                  path={project.frontmatter.path}
                  description={project.frontmatter.description}
                  title={project.frontmatter.title}
                  tags={project.frontmatter.tags}
                />
              </div>
              <div className="row mt-5 mt-lg-8">
                <div className="col-12">
                  <div className="rounded border">
                    <div className="p-5">
                      <p><strong>Got questions or feedback?</strong> Leave a comment, I <strong>will</strong> reply.</p>
                      <Comments
                        pagePath={project.frontmatter.path}
                        pageTitle={project.frontmatter.title}
                        pageIdentifier={project.frontmatter.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <aside className="col-lg-3 col-12 pt-lg-0 pt-5">
              <div className="side-content border ml-lg-5 sticky-top sticky-offset-header rounded">
                <Cta
                  type={project.frontmatter.cta_type}
                  serviceName={project.frontmatter.service_name}
                  actionText={project.frontmatter.action_text}
                  serviceLink={project.frontmatter.service_link}
                  >
                  <div className="cta-content">
                    <p><strong>{project.frontmatter.cta_emphasis}</strong></p>
                    <p>{project.frontmatter.cta_text}</p>
                  </div>
                </Cta>
              </div>
            </aside>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default ProjectTemplate

export const projectQuery = graphql`
  query ProjectPageByPath($path: String!) {
    projectData:
      markdownRemark(frontmatter: {path: {eq: $path} }) {
          id
          htmlAst
          frontmatter {
            path
            title
            description
            date(formatString: "Do MMMM, YYYY")
            updated(formatString: "Do MMMM, YYYY")
            cover_image{
              childImageSharp{
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            static_image
            tags
            cta_type
            service_name
            action_text
            service_link
            cta_emphasis
            cta_text
            ci_badge_url
            ci_badge_alt
            fury_badge_url
            fury_badge_image_url
            fury_badge_alt
          }
    }
    projectMetaData:
      markdownRemark(frontmatter: {path: {eq: $path} }) {
        frontmatter {
          date
          updated
        }
      }
  }
`
